<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmAdminNotificationFormData && Object.keys(vmAdminNotificationFormData).length > 0">
              <div class="form-row">

                  <div class="col-md-12 mb-3">
                    <label for="validationparticipant_id">
                      {{cvParticipantIdLabel}}</label
                    >
                    <input
                      v-model="vmAdminNotificationFormData.participant_id"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationmodule_name">
                      {{cvModuleNameLabel}}</label
                    >
                    <input
                      v-model="vmAdminNotificationFormData.module_name"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationmodule_id">
                      {{cvModuleIdLabel}}</label
                    >
                    <input
                      v-model="vmAdminNotificationFormData.module_id"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationnotification_title">
                      {{cvNotificationTitleLabel}}</label
                    >
                    <input
                      v-model="vmAdminNotificationFormData.notification_title"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationnotification_desc">
                      {{cvNotificationDescLabel}}</label
                    >
                    <input
                      v-model="vmAdminNotificationFormData.notification_desc"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="adminNotificationEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { AdminNotifications } from "../../../FackApi/api/AdminNotification"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "AdminNotificationEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propAdminNotificationObj: {
      type: Object,
      default: function () {
        return {
          "participant_id": "",
          "module_name": "",
          "module_id": "",
          "notification_title": "",
          "notification_desc": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "admin_notification_edit",
      cvCardTitle: "Edit Notification",
      cvSubmitBtn: "Edit",
      cvParticipantIdLabel: "Participant id",
      cvModuleNameLabel: "Module name",
      cvModuleIdLabel: "Module id",
      cvNotificationTitleLabel: "Notification Title",
      cvNotificationDescLabel: "Notification Desc",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "AdminNotification Updated",
      vmAdminNotificationFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.adminNotificationView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmAdminNotificationFormData) {
          if (!this.vmAdminNotificationFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * adminNotificationView
     */
    async adminNotificationView () {
      try {
        if (this.propOpenedInModal) {
          this.vmAdminNotificationFormData = this.propAdminNotificationObj
        }
        else {
          let adminNotificationId = this.$route.params.notification_id
          let adminNotificationViewResp = await AdminNotifications.adminNotificationView(this, adminNotificationId)

          if (adminNotificationViewResp && adminNotificationViewResp.resp_status) {
            this.vmAdminNotificationFormData = adminNotificationViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationView() and Exception:", err.message)
      }
    },
    /**
     * adminNotificationEdit
     */
    async adminNotificationEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let adminNotificationAddResp = await AdminNotifications.adminNotificationEdit(this, this.vmAdminNotificationFormData)
        await ApiResponse.responseMessageDisplay(this, adminNotificationAddResp)

        if (adminNotificationAddResp && !adminNotificationAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseAdminNotificationEditModal", this.vmAdminNotificationFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at adminNotificationEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
